import { createContext, useContext, useState } from 'react';

const SelectedProspectContext = createContext(null);

export const SelectedProspectProvider = (props) => {
  const selectedProspectState = useState({});

  return (
    <SelectedProspectContext.Provider
      value={selectedProspectState}
      {...props}
    />
  );
};

export const useSelectedProspects = () => {
  const context = useContext(SelectedProspectContext);
  if (!context) {
    throw new Error(
      'useSelectedProspect must be used within a SelectedProspectProvider'
    );
  }

  return context;
};
