import _ from 'lodash';
import { ProspectSearchResult } from 'src/components';
import { useLogging } from 'src/context';
import { useSelectedProspects } from 'src/modules/Discovery/context';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';

interface ResultListProps {
  results: any[];
  onResultHover: (arg0) => void;
  selectAllCheckboxRef;
  loading: boolean;
}

const ResultList: React.FC<ResultListProps> = ({
  loading,
  onResultHover,
  results,
  selectAllCheckboxRef,
}) => {
  const log = useLogging();
  const [multiSelectById, setMultiSelectById] = useSelectedProspects();

  function handleFormCheckChange(e: React.ChangeEvent<HTMLInputElement>, row) {
    if (e.currentTarget.checked) {
      log.event('addProspectFromResultListClicked');
      setMultiSelectById((oldState) => {
        const newState = {
          ...oldState,
          [row.provider_id]: row,
        };

        if (_.size(newState) === _.size(results)) {
          selectAllCheckboxRef.current.indeterminate = false;
        }

        return newState;
      });
    } else {
      log.event('removeProspectFromResultListClicked');
      setMultiSelectById((oldState) => {
        const { [row.provider_id]: removedProvider, ...oldStateWithoutRecord } =
          oldState;

        if (
          !_.isEmpty(oldStateWithoutRecord) &&
          selectAllCheckboxRef.current.checked
        ) {
          selectAllCheckboxRef.current.indeterminate = true;
        }

        return oldStateWithoutRecord;
      });
    }
  }

  return (
    <ul
      style={{
        padding: 0,
        opacity: loading ? 0.75 : 1,
      }}
    >
      {_.map(results, (row) => {
        const isChecked = Boolean(
          _.get(multiSelectById, row.provider_id, false)
        );
        return (
          <Box
            component="li"
            key={row.dataTableUniqueKey}
            onMouseEnter={() => {
              onResultHover(row.provider_id);
            }}
            onMouseLeave={() => {
              onResultHover(null);
            }}
            sx={{
              listStyle: 'none',
              borderBottom: `1px ${grey[200]} solid`,
              position: 'relative',
            }}
            aria-label="Search Result Item"
          >
            <ProspectSearchResult
              result={row}
              select={isChecked}
              handleSelect={handleFormCheckChange}
              loading={loading}
            />
          </Box>
        );
      })}
    </ul>
  );
};

export default ResultList;
