import React from 'react';
import HtmlToolTip from 'src/components/HtmlToolTip';
import { ToggleButton, styled, ToggleButtonProps } from '@mui/material';

// This was a solution to the problem of having a tooltip on a toggle button.
// Found here: https://github.com/mui/material-ui/issues/28392

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#2c2c34',
  },
}));

const ToolTipToggleButton: React.FC<
  ToggleButtonProps & { title: string; icon; description }
> = ({ title, icon, description, ...props }) => {
  return (
    <HtmlToolTip title={title} icon={icon} description={description}>
      <StyledToggleButton {...props} />
    </HtmlToolTip>
  );
};

export default ToolTipToggleButton;
