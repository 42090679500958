import { faGlobeAmericas, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ListItem, Typography, useTheme } from '@mui/material';
import React from 'react';

const TerritoryItem = ({ props, option, activeTerritory }) => {
  const theme = useTheme();

  const iconMap = {
    'Map Without Territory': faGlobeAmericas,
    'Add a Territory': faPlus,
  };

  const hasIcon = iconMap[option.name];
  return (
    <ListItem
      {...props}
      key={option.id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        fontSize: '0.9rem',
        fontWeight: option.id === activeTerritory?.id ? 600 : 400,
        borderLeft: `4px ${
          option.id === activeTerritory?.id
            ? theme.palette.highlight.main
            : 'white'
        } solid`,
        '&:hover': {
          borderLeft: `4px ${
            option.id !== activeTerritory?.id
              ? theme.palette.grey[400]
              : theme.palette.highlight.main
          } solid`,
        },
        color: theme.palette.grey[700],
        backgroundColor: 'white !important',
      }}
      aria-label={option.name}
    >
      {hasIcon && (
        <Icon
          icon={iconMap[option.name]}
          size="sm"
          color={theme.palette.grey[600]}
        />
      )}
      <span>{option.name}</span>
      {option?.is_canonical && (
        <Typography
          variant="caption"
          sx={{ color: theme.palette.grey[500], fontWeight: 600 }}
        >
          (assigned)
        </Typography>
      )}
    </ListItem>
  );
};

export default TerritoryItem;
