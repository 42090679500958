import { Button, Tooltip, styled } from '@mui/material';

interface TerritoryPanelToggleButtonProps {
  isSet: boolean;
  onToggleDrawer: () => void;
  label: string;
  disabled?: boolean;
}

const StyledButton = styled(Button)({
  fontSize: '0.75rem',
  '&:disabled': {
    cursor: 'pointer !important',
    pointerEvents: 'all !important',
  },
});

const TerritoryPanelToggleButton = ({
  isSet,
  onToggleDrawer,
  label,
  disabled = false,
}: TerritoryPanelToggleButtonProps) => {
  const tooltipTitle = isSet ? `Hide ${label}` : `Show ${label}`;

  return (
    <Tooltip
      title={
        !disabled
          ? tooltipTitle
          : 'Please select coordinates from map to view analytics'
      }
      aria-label="Territory Analytics Tooltip"
    >
      <span>
        <StyledButton
          variant="text"
          sx={{ fontSize: '0.75rem' }}
          onClick={onToggleDrawer}
          aria-label={`Show ${label}`}
          disabled={disabled}
        >
          {isSet ? 'Hide ' : 'Show '} {label}
        </StyledButton>
      </span>
    </Tooltip>
  );
};

export default TerritoryPanelToggleButton;
