import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const AlertMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  mt: 3,
  paddingBlock: theme.spacing(1),
  fontSize: '.9rem',
  fontWeight: 500,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    top: 'calc(20% - 400px)',
  },
}));

const TerritoryDeletionDialog = ({
  open,
  handleClose,
  territory,
  error,
  isDeleting,
  setIsDeleting,
  confirm,
}) => {
  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle>Delete Territory</DialogTitle>
      <DialogContent>
        Are you sure you want to delete <strong>{territory?.name}</strong>?
        {!!error && (
          <AlertMessage>
            There was an error deleting this territory, please try again.
          </AlertMessage>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            if (isDeleting) return;

            setIsDeleting(null);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          sx={{ minWidth: 80 }}
          variant="contained"
          loading={isDeleting}
          disabled={isDeleting}
          onClick={confirm}
          aria-label="Confirm"
        >
          <span>Confirm</span>
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default TerritoryDeletionDialog;
